<template>
    <div id="print-invoice">
        <div class="row m-2">
            <div class="col-md-12 mb-3" id="btn-back" @click="goBack">
                <div  class="size-14 main-link">
                    <!-- <router-link :to="'/account/orders'" class="size-14 main-link"> -->
                        <icon-arrow :color="'var(--blue-600)'" class="hover-blue" :size="12" style="transform: rotate(180deg);"/>
                        {{$t("account.back_to_my_orders")}}
                    <!-- </router-link> -->
                </div>
            </div>
            <div class="col-md-6" id="logo">
                <img src="../../../common/assets/images/Group 3726.png" alt="">
            </div>
            <div class="col-md-6 align-items-end justify-content-end" id="qr-code">
                <div class="d-flex flex-column align-items-center">
                    <div class="position-relative">
                        <div class="ocrloader d-flex align-items-center justify-content-center">
                            <qr-code :text="checkDocumentId" :size="100" color="#000" bg-color="transparent" error-level="L"></qr-code>
                            <div class="bottom-line"></div>
                        </div>
                    </div>
                    <span class="subtitle-14 color-dark-gray-400 weight-300 mt-2">{{$t("account.invoice_number")}}</span>
                </div>
            </div>
            <div class="justify-content-between" id="print-logo">
                <div>
                    <img src="../../../common/assets/images/Group 3726.png" alt="">
                </div>
                <div class="d-flex flex-column align-items-center">
                    <div class="position-relative">
                        <div class="ocrloader d-flex align-items-center justify-content-center">
                            <qr-code :text="checkDocumentId" :size="100" color="#000" bg-color="transparent"  error-level="L"></qr-code>
                            <div class="bottom-line"></div>
                        </div>
                    </div>
                    <span class="subtitle-14 color-dark-gray-400 weight-300 mt-2">{{$t("account.invoice_number")}}</span>
                </div>
            </div>
            <div class="col-md-12">
                <h5 class="title-24 mb-0 font-weight-bold">{{$t("account.invoice")}}<a href="#" v-print="'print-invoice'" @click.prevent="" class="size-14 ms-2" id="btn-print">{{$t("account.print")}}</a></h5>
            </div>
            <div class="col-md-12 justify-content-between" id="print-infomation">
                <div>
                    <div class="d-flex flex-column mt-4" style="max-width: 302px">
                        <h6 class="title-16 mb-0 font-weight-bold">Mundo Móvil MH</h6>
                        <span class="subtitle-14">RNC: <span class="color-dark-gray-200">131-12214-2</span> </span>
                        <span class="subtitle-14">{{$t("account.phone")}}: <span class="color-dark-gray-200">809-581-7666</span> </span>
                        <span class="subtitle-14">Email: <span class="color-dark-gray-200">Info@mundomovil.com.do</span> </span>
                        <span class="subtitle-14">Web: <span class="color-dark-gray-200">www.mundomovil.com.do</span> </span>
                        <span v-if="invoice.employee_name" class="subtitle-14">{{$t("account.seller")}}: <span class="color-dark-gray-200 text-capitalize">{{invoice.employee_name | textFormat}}</span> </span>
                    </div>
                </div>
                <div>
                    <div class="d-flex flex-column mt-4" style="max-width: 302px">
                        <h6 class="title-16 mb-0 font-weight-bold">Cliente</h6>
                        <span class="subtitle-14">{{$t("account.name")}}: <span class="color-dark-gray-200 text-capitalize">{{invoice.entity.first_name | textFormat}}</span> </span>
                        <span class="subtitle-14">{{$t("account.phone")}}: <span class="color-dark-gray-200">{{invoice.entity.phone | phoneFormat}}</span> </span>
                        <span class="subtitle-14" v-if="invoice.customer_rnc">RNC: <span class="color-dark-gray-200">{{invoice.customer_rnc}}</span> </span>
                        <span class="subtitle-14" v-if="invoice.ncf && invoice.ncf != '-'">NCF: <span class="color-dark-gray-200">{{invoice.ncf}}</span> </span>
                    </div>
                </div>
                <!-- <div class="d-flex justify-content-end col-md-6" v-if="invoice.order_primary.option_name  != undefined && invoice.order_primary.option_name != 'pick_up'">
                    <div class="d-flex flex-column text-end mt-4" style="max-width: 302px">
                        <h6 class="title-16 mb-0 font-weight-bold">{{$t("account.shipping_address")}}</h6>
                        <span class="subtitle-14">{{invoice.order_primary.optionaddress.address.name}} | Tel: <span class="color-dark-gray-200">{{invoice.order_primary.optionaddress.address.phone | phoneFormat}}</span> </span>
                        <span class="subtitle-14"> {{invoice.order_primary.optionaddress.address.address_line2 || invoice.order_primary.optionaddress.address.address_line}}, {{invoice.order_primary.optionaddress.address.city_name}} </span>
                        <span class="subtitle-14">Ref: <span class="color-dark-gray-200">{{invoice.order_primary.optionaddress.address.reference}}.</span> </span>
                    </div>
                </div> -->
            </div>
            <div id="company" class="col-md-6">
                <div class="d-flex flex-column mt-4" style="max-width: 302px">
                    <h6 class="title-16 mb-0 font-weight-bold">Mundo Móvil MH</h6>
                    <span class="subtitle-14">RNC: <span class="color-dark-gray-200">131-12214-2</span> </span>
                    <span class="subtitle-14">{{$t("account.phone")}}: <span class="color-dark-gray-200">809-581-7666</span> </span>
                    <span class="subtitle-14">Email: <span class="color-dark-gray-200">Info@mundomovil.com.do</span> </span>
                    <span class="subtitle-14">Web: <span class="color-dark-gray-200">www.mundomovil.com.do</span> </span>
                    <span v-if="invoice.employee_name" class="subtitle-14">{{$t("account.seller")}}: <span class="color-dark-gray-200 text-capitalize">{{invoice.employee_name | textFormat}}</span> </span>
                </div>
            </div>
            <!-- ------------------- CUSTOMER DATA EMPLEADO ------------------- -->
            <div id="company" class="col-md-6 d-flex justify-content-end col-md-6">
                <div class="d-flex flex-column mt-4" style="max-width: 302px">
                    <h6 class="title-16 mb-0 font-weight-bold">Cliente</h6>
                    <span class="subtitle-14">{{$t("account.name")}}: <span class="color-dark-gray-200 text-capitalize">{{invoice.entity.first_name  | textFormat}}</span> </span>
                    <span class="subtitle-14">{{$t("account.phone")}}: <span class="color-dark-gray-200">{{invoice.entity.phone | phoneFormat}}</span> </span>
                    <span class="subtitle-14" v-if="invoice.customer_rnc">RNC: <span class="color-dark-gray-200">{{invoice.customer_rnc}}</span> </span>
                    <span class="subtitle-14" v-if="invoice.ncf && invoice.ncf != '-'">NCF: <span class="color-dark-gray-200">{{invoice.ncf}}</span> </span>
                </div>
            </div>
            <!-- ------------------- CUSTOMER DATA EMPLEADO ------------------- -->
            <!-- <div id="address" class="d-flex justify-content-end col-md-6" style="margin-top: -130px;" v-if="invoice.order_primary.option_name != undefined && invoice.order_primary.option_name != 'pick_up'">
                <div class="d-flex flex-column text-end mt-4" style="max-width: 302px">
                    <h6 class="title-16 mb-0 font-weight-bold">{{$t("account.shipping_address")}}</h6>
                    <span class="subtitle-14">{{invoice.order_primary.optionaddress.address.name}} | Tel: <span class="color-dark-gray-200">{{invoice.order_primary.optionaddress.address.phone | phoneFormat}}</span> </span>
                    <span class="subtitle-14"> {{invoice.order_primary.optionaddress.address.address_line2 || invoice.order_primary.optionaddress.address.address_line}}, {{invoice.order_primary.optionaddress.address.city_name}} </span>
                    <span class="subtitle-14">Ref: <span class="color-dark-gray-200">{{invoice.order_primary.optionaddress.address.reference}}.</span> </span>
                </div>
            </div> -->
            <!-- <div id="address" class="d-flex justify-content-end col-md-6" style="margin-top: -130px;" v-if="invoice.order_primary.option_name  != undefined && invoice.order_primary.option_name == 'pick_up'">
                <div class="d-flex flex-column text-end mt-4" style="max-width: 302px">
                    <h6 class="title-16 mb-0 font-weight-bold">{{$t("account.shipping_address")}}</h6>
                    <span class="subtitle-14">{{invoice.order_primary.optionaddress.place.description}} | Tel: 
                        <span class="color-dark-gray-200">{{invoice.order_primary.optionaddress.place.telephone | phoneFormat}}</span> 
                    </span>
                    <span class="subtitle-14 text-capitalize"> {{invoice.order_primary.optionaddress.place.address | textFormat}}, {{invoice.order_primary.optionaddress.place.city | textFormat}} </span>
                    <span class="subtitle-14 text-capitalize" v-if="invoice.order_primary.partner_id == 0"> {{user.entity.first_name | textFormat}} {{user.entity.last_name | textFormat}}</span>
                    <span class="subtitle-14 text-capitalize" v-else> {{invoice.order_primary.partner.full_name | textFormat}}</span>
                </div>
            </div> -->
            <div class="col-md-12 my-5" id="details">
                <div class="row ms-0 me-0 mr-0 ml-0 p-4 container-information" style="background: #F4F7F9; border-radius: 18px;">
                    <div class="col-md-3 py-4">
                        <h6 class="title-16 weight-700 font-weight-bold mb-0">{{$t("account.invoice_number")}}</h6>
                        <span class="subtitle-16">{{invoice.document_id}}</span>
                    </div>
                    <div class="col-md-3 py-4">
                        <h6 class="title-16 weight-700 font-weight-bold mb-0">{{$t("account.order_placed")}}</h6>
                        <span class="subtitle-16"> {{invoice.created_at.slice(8,10)}} {{ invoice.created_at.slice(5,7) | monthFormat}} {{invoice.created_at.slice(0,4)}}</span>
                    </div>
                    <div class="col-md-3 py-4">
                        <h6 class="title-16 weight-700 font-weight-bold mb-0">{{$t("account.time")}}</h6>
                        <span class="subtitle-16">{{invoice.created_at.slice(11,19)}}</span>
                    </div>                    
                    <div class="col-md-3 py-4">
                        <h6 class="title-16 weight-700 font-weight-bold mb-0">{{$t("account.branch")}}</h6>
                        <span class="subtitle-16">{{filterBranch.description | textFormat}}, {{filterBranch.city_name | textFormat}}</span>

                    </div>
                </div>
            </div>
            <div id="print-table">
                <div class="row ms-0 me-0 mr-0 ml-0 p-4 container-information" style="border-radius: 18px; box-shadow: inset 0 0 0 1000px whitesmoke;">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div>
                                <h5 class="title-16 weight-700 font-weight-bold mb-0">{{$t("account.invoice_number_")}}</h5>
                                <span class="subtitle-16">{{invoice.document_id}}</span>
                            </div>
                            <div class="mt-3">
                                <h5 class="title-16 weight-700 font-weight-bold mb-0 text-capitalize">{{$t("account.branch")}}</h5>
                                <span class="subtitle-16">{{filterBranch.description | textFormat}}, {{filterBranch.city_name | textFormat}}</span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <h5 class="title-16 weight-700 font-weight-bold mb-0">{{$t("account.order_placed")}}</h5>
                                <span class="subtitle-16">{{invoice.created_at.slice(8,10)}} {{ invoice.created_at.slice(5,7) | monthFormat}} {{invoice.created_at.slice(0,4)}}</span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <h5 class="title-16 weight-700 font-weight-bold mb-0">{{$t("account.time")}}</h5>
                                <span class="subtitle-16">{{invoice.created_at.slice(11,19)}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <table class="table table-striped">
                    <thead style="border-bottom:2px solid var(--gray-100);border-top: 1px solid var(--light-gray-100)">
                        <tr>
                            <th scope="col" class="title-18 ps-md-4">{{$t("checkout.product_details")}}</th>
                            <th scope="col" class="text-center title-18">{{$t("checkout.quantity")}}</th>
                            <th scope="col" class="text-center title-18">{{$t("checkout.price")}}</th>
                            <th scope="col" class="text-center title-18">{{$t("checkout.total")}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="product in invoice.order_details" :key="product.product.codarticulo">
                            <td class="py-3 ps-md-4">
                                <div>
                                    <h6 class="text-limited title-16 product-description mb-0">
                                            ({{ product.product.codarticulo  }}) {{ product.product.description | textFormat }}
                                    </h6>
                                    <div class="d-flex mb-0" >
                                        <span class="subtitle-14 me-3 mr-3">{{$t("product.color")}}: <span class="ms-1 ml-1 color-dark-gray-300 text-secondary">{{product.product.color ? (product.product.color) : 'N/a' | textFormat}}</span></span>
                                        <span class="subtitle-14">{{$t("product.brand")}}: <span class="ms-1 ml-1 color-dark-gray-300 text-secondary">{{product.product.brand ? (product.product.brand) : 'N/a' | textFormat}}</span></span>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center align-middle py-3">{{product.quantity}}</td>
                            <td class="text-center align-middle py-3">{{product.price_sold | moneyFormat}}</td>
                            <td class="text-center align-middle py-3">{{product.price_sold * product.quantity | moneyFormat}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-12">
                <div class="d-flex justify-content-end">
                    <ul class="p-0 list-unstyled" style="min-width: 250px; margin-right: calc(4% - 6px);">
                        <li class="d-flex justify-content-between subtitle-16 mt-2 color-dark-gray-300">{{$t("account.subtotal")}} <span>{{invoice.amount_sub | moneyFormat}}</span> </li>
                        <li class="d-flex justify-content-between subtitle-16 mt-2 color-dark-gray-300">{{$t("account.tax")}} <span>{{invoice.amount_tax | moneyFormat}}</span></li>
                        <li class="d-flex justify-content-between title-16 mt-2 mb-0 weight-700 font-weight-bold">Total<span style="color:#BC2C35;">{{invoice.amount | moneyFormat}}</span></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-12 d-flex flex-column justify-content-center align-items-center" style="margin-top: 5rem;">
                <h4 class="title-24 mb-0 weight-700">{{$t("account.greetings")}}</h4>
                <span class="color-dark-gray-300 weight-400 mt-3 size-16">{{$t("account.follow_us")}}</span>
                <span class="color-dark-gray-300 weight-400 size-16" style="line-height: 10px;">@mundomovil</span>
                <div class="mt-4">
                    <icon-twitter :size="32" />
                    <icon-instagram :size="28" style="margin-left: 2rem;"/>
                    <icon-facebook :size="14" style="margin-inline: 2rem;" />
                    <icon-youtube :size="32" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QrCode from 'vue-qrcode-component'
import { mapGetters } from "vuex"
import IconArrow from '../../../common/svg/iconArrow.vue'
export default {
    name: "InvoiceIntranet",
    components: {
            QrCode,
            IconTwitter: () => import("@/modules/layout/assets/svg/footer/iconTwitter.vue"),
            IconInstagram: () => import("@/modules/layout/assets/svg/footer/iconInstagram.vue"),
            IconFacebook: () => import("@/modules/layout/assets/svg/footer/iconFacebook.vue"),
            IconYoutube: () => import("@/modules/layout/assets/svg/footer/iconYoutube.vue"),
            IconArrow,
        },
    computed: {
        ...mapGetters({
            invoice: "account/invoice",
            branches: "branches/branches",
            branchSelected: "branches/branchSelected",
            user: "auth/userData"
        }),
        filterBranch(){
            return this.$options.filters.filterBranch(this.branches, this.invoice.branch_code || this.branchSelected.codplace);
        },
        checkDocumentId(){
            return this.invoice?.document_id
        },
    },
    methods: {
        goBack(){
            this.$router.go(-1)
        },
    },
    beforeDestroy(){
        localStorage.removeItem('invoice')
    }
}
</script>

<style>
    #print-invoice {
        max-width: 1100px;
        margin: auto;
    }
    .container-information {
        background: var(--light-gray-100);
        border-radius: 18px;
    }
    .ocrloader p::before {
        content: '';
        display:inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #4979B2;
        position: relative;
        right: 4px;
    }
    .ocrloader p {
        color: #4979B2;
        position: absolute;
        bottom: -30px;
        left: 38%;
        font-size: 16px;
        font-weight: 600;
        animation: blinker 1.5s linear infinite;
        font-family: sans-serif;
        text-transform: uppercase;
    }

    .ocrloader {
        width: 135px;
        height: 130px;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .ocrloader:before,
    .ocrloader:after,
    .ocrloader .bottom-line:after,
    .ocrloader .bottom-line:before {
        border-color: #4979B2;
        content: "";
        position: absolute;
        width: 35px;
        height: 35px;
        border-style: solid;
        border-width: 0px;
    }
    .ocrloader:before {
        left: 0;
        top: 0;
        border-left-width: 3.5px;
        border-top-width: 3.5px;
        border-radius: 5px 0 0 0;
    }
    .ocrloader:after {
        right: 0;
        top: 0;
        border-right-width: 3.5px;
        border-top-width: 3.5px;
        border-radius: 0 5px 0 0;
    }
    .ocrloader .bottom-line:before {
        left: 0;
        bottom: 0;
        border-left-width: 3.5px;
        border-bottom-width: 3.5px;
        border-radius: 0 0 0 5px;
    }
    .ocrloader .bottom-line:after {
        right: 0;
        bottom: 0;
        border-right-width: 3.5px;
        border-bottom-width: 3.5px;
        border-radius: 0 0 5px 0;
    }
    .table-striped>tbody>tr:nth-of-type(odd)>*{
        --bs-table-accent-bg: var(--light-gray-100)
    }
    .table>:not(caption)>*>*{
        border-color: var(--gray-100)
    }
    @page {
        size: auto;  /* auto is the initial value */
        margin: 0mm; /* this affects the margin in the printer settings */
    }
    #print-table, #print-logo, #print-infomation{
        display: none;
    }
    #details, #logo{
        display: block;
    }
    #qr-code{
        display: flex;
    }
    #btn-print{
        display: initial;
    }
    
            #print-table > table > thead > tr >th{
            padding: 0;
        }
    @media print {
        #btn-back,  #btn-print, #details, #logo, #qr-code, #company, #address{
            display: none !important;
        }
        #print-table{
            display: block !important;
        }
        #print-logo, #print-infomation{
            display: flex;
        }
        #print-table{
            margin: 20px 0px;
        }
    }
    .hover-blue:hover{
        fill: var(--blue-600);
        cursor: pointer;
    }
</style>
